@import '../../../colors.scss';

.navbarWrapper {
    background-color: transparent !important;
    color: $primary !important;
    position: relative !important;
    top: 0 !important;
    box-shadow: none !important;
}

.navbarButton {
    border-radius: 0px !important;

    .navbarText {
        color: $white !important;
        text-transform: capitalize !important;
    }
}

.navbarButton:hover {
    transition: 0.5s ease-in-out !important;
    border-radius: 0px !important;
}

.navbarButtonActive {
    // background-color: rgba($primary , 0.15);
    border-radius: 0px;
    border-bottom: 1px solid $white;

    .navbarText {
        // font-weight: bold !important;
        color: $white !important;
        text-transform: capitalize !important;
    }
}

.loginButton {
    background-color: transparent !important;
    border: 1px solid $white !important;
    padding: 4px 16px !important;
    color: $white !important;
    font-weight: bold !important;
    border-radius: 8px !important;
    border: none;
    text-transform: capitalize !important;
}