@font-face {
    font-family: raleway;
    src: url(./assets/fonts/Raleway-Regular.ttf);
}

* {
    font-family: 'Poppins', sans-serif;
}

body::-webkit-scrollbar {
    display: none;
}