@import '../../../colors.scss';

.systemFirstComponent {
    margin-top: 65vh;
    margin-bottom: 48px;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($primary, 0.5);
    border-radius: 0px;
}

.systemFirstComponentCard {
    width: 100%;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.systemFirstComponentMainContent,
.systemFirstComponentSidebar {
    transition: all 0.3s ease;
}

.systemFirstComponentContentTitle {
    font-size: 1.25rem;
    color: $secondary;
    font-weight: bold !important;
    margin-bottom: 12px;
}

.systemFirstComponentContent {
    color: $black;
}

.systemFirstComponentSideContent {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background-color: rgba($primary , 0.3);
    border-radius: 8px;
    margin-bottom: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba($primary , 0.5);
    }

    .systemBoxCheck {
        color: $primary;
    }
}
