@import '../../../colors.scss';

.AboutUsSecondComponentWrapper {
    margin-top: 65vh;
    background-color: rgba($primary, 0.2);

    .AboutUsSecondComponentTitlesContainer {
        padding-right: 16px;

        .AboutUsSecondComponentTitle {
            color: $primary;
            font-weight: bold;
            margin-bottom: 16px;
        }

        .AboutUsSecondComponentSubTitle {
            color: $secondary;
        }

        .AboutUsSecondComponentText {
            color: $secondary;

            .AboutUsSecondComponentBoxCheck {
                color: $primary;
            }
        }
    }

    .AboutUsSecondComponentCardsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        .AboutUsSecondComponentCardsContainerCard {
            width: 100% !important;
            height: 40vh;
            display: flex;
            justify-content: flex-start; 

            .AboutUsSecondComponentCardsContainerBox {
                padding: 16px;
                .AboutUsSecondComponentCardsContainerContent {
                    h2 {
                        color: $primary;
                    }

                    h4 {
                        color: $secondary;
                    }

                    p {
                        color: $secondary;
                    }
                }
            }

            .AboutUsSecondComponentCardsImageContainer {
                width: 100% !important;
                height: 40vh !important;

            }
        }
    }
}