@import '../../../colors.scss';


.HomePageThirdComponentWrapper {
    background-color: rgba($primary,0.2);
    padding: 64px 16px;
    margin-top: 64px;
    height: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row;

        .HomePageThirdComponentDataBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .HomePageThirdComponentDataImage {
                background-color: $primary;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
            }

            .HomePageThirdComponentDataText {
                text-align: center;
            }

            .HomePageThirdComponentDataButton{
                text-transform: capitalize !important;
                margin-top: 16px !important;
                background-color: rgba($primary, 0.8) !important;
                border-radius: 8px !important;
                color:$white !important;
                width: 50% !important;
                text-align: center !important;
            }
        }
    

}