@import '../../../colors.scss';

.HomePageFourthComponentWrapper {
    padding: 32px 0px;

    .HomePageFourthComponentTitlesContainer {
        text-align: center;

        .HomePageFourthComponentTitle {
            color: $primary;
            font-weight: bold;
            padding-bottom: 24px;
        }
    }

    .HomePageFourthComponentCardsContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-top: 32px;

        .HomePageFourthComponentCard {
            border-radius: 0px;
            width: 400px;
            height: auto;
            padding: 24px;
            cursor: pointer;
        
            &:nth-child(1), &:nth-child(2) {
                border-right: 2px solid $secondary;
            }

            .HomePageFourthComponentCardIconContainer {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                gap: 8px;
                border-radius: 0px;
                color: $secondary;
                transition: 0.5s ease-in-out;
            }

            .HomePageFourthComponentCardTitle {
                color: $secondary;
                height: 70px;
                font-weight: bold;
                text-align: left;
            }

            .HomePageFourthComponentCardTitlesContainer {
                display: flex;
                text-align: left;
                color: $black;
                margin-top: 8px;
                transition: 0.3s ease-in-out;

                .HomePageFourthComponentCardSub {
                    opacity: 0.8;
                }
            }

            .HomePageFourthComponentButtonContainer {

                .HomePageFourthComponentButton {
                    opacity: 0;
                    transition: 0.5s ease-in-out !important;
                    color: $white ;
                    margin-top: 16px;
                    font-size: 12px !important;
                }
            }
        }
    }
}