@import '../../../colors.scss';

.footerWrapper {
    background-color: $primary;
    padding-top: 8px;
    padding-bottom: 8px;
    color: $white;

    .socialIcon {
        color: $white;
        margin: 0 10px;
        font-size: 20px;
        transition: color 0.3s;

        &:hover {
            color: $secondary;
        }
    }

    .footerLink {
        color: $white;
        margin-bottom: 8px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .footerCopyRight {
        opacity: 0.8;
        text-align: center;
        font-size: 14px;
        margin-top: 16px;
    }

    .footerButton {
        border: 1px solid $white !important;
        background-color: transparent !important;
    }
}