@import '../../colors.scss';

.ticketsWrapper{
    width: 100%;
    height: 70vh;
    background-image: url(../../assets/images/page-background.jpeg);
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;
    position: absolute;
    top: 0;  
   
    .ticketsOverlayWrapper{
        background-color: rgba($black , 0.7);
        width: 100%;
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;

        h1{
            color: $white;
        }
    }

    .ticketsContainer{
        width: 100%;
        height: 500px;
        overflow-y: auto;
        background-color: $white;
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 10px rgba(8, 7, 16, 0.6);
        border-radius: 8px;
        padding: 16px;
        margin-top: -15vh;
        
        .ticketProgressTable {
            width: 100%;
            margin: 0;
            position: relative;
            border-collapse: collapse;
    
            .tableHeader {
                padding: 16px;
                position: sticky;
                top: -16px;
                background-color: $primary;
                color: $white;
                text-align: center;
            }
    
            .tableHeader:nth-child(1) {
                border-radius: 10px 0px 0px 10px;
            }
    
            .tableHeader:nth-child(3) {
                border-radius: 0px 10px 10px 0px;
            }
        }
    }
}