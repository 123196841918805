@import '../../colors.scss';

.signinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba($primary , 0.3);

    form {
        width: 600px;
        background: $white;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;


        h3 {
            margin-bottom: 10px;
            color: $primary;
        }

        label {
            align-self: flex-start;
            margin-top: 20px;
            color: $primary;
        }

        input {
            width: 100%;
            padding: 12px;
            margin-top: 8px;
            background: $white;
            border: 1px solid $primary;
            outline: none !important;
            color: $secondary;
            border-radius: 8px;
        }

        button {
            margin-top: 30px;
            width: 100%;
            padding: 10px;
            background: $primary;
            color: #ffffff;
            font-weight: bold;
            border-radius: 10px;
            cursor: pointer;
            border: 1px solid $white;
            transition: background-color 0.3s;

            &:hover {
                background: rgba($primary , 0.8);
            }
        }

    }
}