@import '../../../colors.scss';

.AboutUsFirstComponentContainer {
    width: 100%;
    height: 80vh;
    position: absolute;
    top: 0 !important;
    background-image: url(../../../assets/images/page-background.jpeg);
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;
    
    .AboutUsFirstComponentOverlay {
        background-color: rgba($black, 0.8);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__Title {
            padding: 0px 32px;
            text-transform: capitalize;
            color: $white;
            font-weight: bold;
        }

        &__SubTitle {
            text-transform: capitalize;
            color: rgba($white , 0.8);
            margin: 32px 0px;
        }
    }
}