@import '../../../colors.scss';

.HomePageSecondComponentContainer {
    background-color: $white !important;
    height: 43vh;
    margin-top: 52vh;
    margin-bottom: 100px;
    position: relative;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .HomePageSecondComponentTitleContainer {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .HomePageSecondComponentContentContainer {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 16px;
        flex-wrap: wrap;

        .HomePageSecondComponentBoxContainer {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            

            .HomePageSecondComponentTitle {
                color: $primary;
                font-weight: bold;
            }

            .HomePageSecondComponentSubTitle {
                margin-top: 16px;
                color: $black;
            }

            .HomePageSecondComponentBoxTitle {
                color: $primary !important;
            }
        }
    }
}