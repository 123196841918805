@import '../../../colors.scss';

.tab-container {
    max-width: 100%;
    margin: 32px auto;
    display: flex;
}

.tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    border-radius: 0px;
    margin-bottom: 20px;
}

.tab {
    padding: 24px 16px;
    border: none;
    color: $primary;
    background-color: transparent;
    cursor: pointer;
    transition: font-weight 0.3s ease;
    outline: none;
    font-size: 16px;
    white-space: nowrap;
    border-left: 4px solid $white;
}

.tab.active {
    background-color: rgba($primary,0.1);
    border-radius: 0px;
    color: $primary;
    border-left: 4px solid $primary;
}

.tab-content {
    margin-left: 16px;
    padding: 16px;
    border-radius: 0px;
}

.tab-content div {
    padding: 10px;
    color: #333;
}

.tab-panel {
    display: none;
}

.tab-panel.active {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.hidden {
    display: none;
}