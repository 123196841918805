@import '../../../colors.scss';

.AboutUsFourthComponentWrapper {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url(../../../assets/images/bottomcomponent.jpeg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    .AboutUsFourthComponentContainer {
        background-color: rgba($black , 0.6);
        padding: 48px 0px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .AboutUsFourthComponentTitle {
            color: $white;
            font-weight: bold;
        }

        .AboutUsFourthComponentSubTitle {
            margin-top: 16px;
            color: $white;
        }

        .AboutUsFourthComponentLink {
            margin-top: 32px;

            .AboutUsFourthComponentButton {
                color: $white;
                background-color: transparent;
                border: 2px solid $white !important;
                border: none;
                border-radius: 4px;
                padding: 12px 24px;
                font-size: 16px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                text-transform: capitalize;

                &:hover {
                    background-color: rgba($white, 0.8);
                    color: $primary;
                }
            }
        }
    }

}