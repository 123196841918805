@import '../../../colors.scss';

.SystemThirdComponentWrapper {
    padding: 24px;
    margin-top: 16px;
    width: 100%;
    // background-color: $fourth; // Light background for better readability

    .SystemThirdComponentTitle {
        color: $primary; // Use primary color from your theme
        text-align: center;
        margin-bottom: 20px;
    }

    .SystemThirdComponentAccordion {
        background-color: #fff; // White background for each accordion
        color: $black; // Dark text for readability

        &:not(:last-of-type) {
            border-bottom: 1px solid $primary; // Separation between accordions
        }

        .MuiAccordionSummary-root {
            // background-color: rgba($secondary, 0.8); // Light primary color for summary
            color: $secondary; // Ensure text is visible

            &:hover {
                // background-color: $secondary; // Darken on hover
                color: $secondary;
                font-weight: bold;
            }
        }

        .MuiAccordionDetails-root {
            padding: 16px;
            background-color: $white; // Slightly lighter than the main background
        }
    }

    .MuiAccordion-root:before {
        background-color: transparent; // Remove the default MUI line
    }

    .SystemThirdComponentContentText {
        color: $secondary;
    }
}