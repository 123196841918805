@import '../../../colors.scss';

.ScenariosComponentUpdated {
   margin-top: 70vh;

    .scenario-card {
        box-shadow: none !important;
        height: 300px;
        border-radius: 0px;
        border-bottom: 1px solid $primary;
    }

    .scenario-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .scenario-content-image {
            background-color: rgba($primary , 0.7);
            display: inline-block;
            padding: 8px;
            border-radius: 24px;
            margin-bottom: 16px;

        }
        .scenario-content-h5 {
            color: $black;
            font-weight: bold;
            text-align: center;
            height: 80px;
        }

        .scenario-content-span {
            color: $black;
            text-align: center;
        }
    }

    .selected-scenario-details {
        margin-top: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;

        .selected-scenario-image {
            margin-bottom: 32px;
            padding: 16px;
            border-radius: 16px;
            width: 50%;
            max-width: 200px;
            height: auto;
            background-color: $primary;
        }
    }
}